.contact_us {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
}

.card {
    width: 400px;
    height: 450px;
    border-radius: 18px;
    background-color: #9c9c9c16;
    padding: 40px;

    box-shadow: 0px 8px 58px rgba(96, 80, 47, 0.171);

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    h2 {
        text-align: center;
        font-size: 18px;
    }

    svg {
        width: 90px;
        height: 90px;

        &:hover {
            animation: jump 1s;
        }
    }
}

.contact_numbers,
.emails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
        text-decoration: none;
        color: white;
        font-size: 16px;

        &:hover {
            color: #a37826;
        }
    }
}

.contact__divider {
    height: 400px;
    width: 1px;
    background-color: #a37826;
}

.company_name {
    font-size: 16px;
}

.company_address {
    font-size: 16px;
    text-align: center;
}

//************** Queries ***************//
/*
    LS -> Large Screen
    MS -> Medium Screen
    SS -> Small Screen
*/

//? LS - Width
@media (max-width: 1000px) {
    .contact_us {
        gap: 30px;
    }

    .card {
        width: 300px;
        height: 350px;
        border-radius: 16px;
        padding: 30px;

        h2 {
            font-size: 16px;
        }

        svg {
            width: 60px;
            height: 60px;
        }
    }

    .contact__divider {
        height: 300px;
    }

    .company_name {
        font-size: 14px;
    }

    .company_address {
        font-size: 14px;
    }
}

//? MS - Width
@media (max-width: 690px) {
    .contact_us {
        flex-direction: column;
        height: fit-content;
        padding: 100px 0 60px 0;
    }

    .contact__divider {
        display: none;
    }

    .card {
        width: 400px;
    }
}

//? MS - Height
@media (max-height: 650px) {
    .contact_us {
        height: fit-content;
        padding: 100px 0 60px 0;
    }
}

//? SS - Width
@media (max-width: 464px) {
    .card {
        width: 300px;
        height: 350px;
    }
}

//************** Animation ***************//

@keyframes jump {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}
