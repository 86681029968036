.hero-section {
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-container {
    width: 75%;
    height: 90%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.hero-heading {
    font-size: 9.5rem;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    span {
        font-family: "Shoreline";
    }
}

.hero-para-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .hero-para {
        font-size: 2rem;
        letter-spacing: 2px;
        font-weight: 200;
        color: rgb(240, 240, 240);
        text-align: center;
    }
}

// When sidebar (left) comes at top
@media (max-width: 1200px) {
    .hero-section {
        margin-top: 40px;
    }
}

@media (max-width: 1100px) {
    .hero-section {
        height: 90vh;
    }

    .hero-container {
        justify-content: center;
        gap: 100px;
    }

    .hero-heading {
        font-size: 6rem;
        gap: 10px;
    }
}

@media (max-width: 609px) {
    .hero-para-container {
        .hero-para {
            font-size: 2.6rem !important;
        }
    }
}

@media (max-width: 458px) {
    .hero-container {
        gap: 80px;
    }

    .hero-para-container {
        .hero-para {
            font-size: 2rem !important;
        }
    }
    .hero-heading {
        font-size: 4.8rem;
    }
}

@media (max-width: 363px) {
    .hero-heading {
        font-size: 4rem;
    }
}

// Height Queries

@media (max-height: 500px) {
    .hero-section {
        height: 500px;
    }
}
