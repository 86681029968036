.not_found {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  .not_found_wrapper {
    margin: 0 auto;
    position: relative;
    width: 1100px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    h3 {
      font-size: 8rem;
      text-transform: capitalize;
    }
    p {
      font-size: 2rem;
      margin: 3rem 0;
    }
    .btns_ {
      display: flex;
      margin-top: 1rem;
      button {
        width: 20rem;
        height: 5.5rem;
        border: 2px solid white;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 4rem;
        font-weight: bold;
        cursor: pointer;
        margin-right: 2rem;
        transition: all 200ms linear;
        span {
          color: white;
          font-size: 2rem;
        }
        &:hover {
          width: 22rem;
          margin-left: 0rem;
          letter-spacing: 2px;
        }
      }
    }
  }
  .err {
    height: 80%;
    width: 40%;
    position: absolute;
    right: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    span {
      font-size: 45rem;
      color: rgb(58, 49, 49);
    }
  }
}

@media screen and (max-width: 1100px) {
  .not_found {
    .not_found_wrapper {
      width: 100%;
      padding: 2rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .not_found {
    .not_found_wrapper {
      width: 100%;
      padding: 5rem;
      margin-top: 10rem;
      p {
        margin: 2rem 0;
      }
      button {
        margin-top: 3rem;
      }
    }
    .err {
      left: 16rem;
      top: 0;
      height: auto;
      span {
        font-size: 30rem;
        font-weight: bolder;
      }
    }
  }
}
