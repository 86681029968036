.hotels-list-container {
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: fixed;
    z-index: 3;

    display: flex;
    justify-content: center;
    align-items: center;

    .hotels-list-box {
        width: 80%;
        height: fit-content;
        max-height: 80%;

        display: flex;
        flex-direction: column;
        gap: 20px;

        .hotels-list-header {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 10px 0px;
            background-color: rgb(23, 23, 23);
            position: sticky;
            top: 0px;

            svg {
                color: goldenrod;
                cursor: pointer;
                margin-right: 10px;
            }
        }

        .hotels-list-main {
            background-color: rgb(23, 23, 23);
            overflow-y: scroll;
            display: grid;
            grid-template-columns: 1fr 1fr;

            // set height = 1000px to make list container have max height when loading circle is visible
            height: 1000px;

            .hotels-list-left,
            .hotels-list-right {
                padding: 40px;
                background-color: rgb(23, 23, 23);

                .continent-box {
                    .continent {
                        font-weight: 500;
                        font-size: 4rem;
                        color: rgb(105, 105, 105);
                        border-bottom: 3px solid rgb(68, 68, 68);
                    }
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    .hotels-list-container {
        .hotels-list-box {
            .hotels-list-main {
                grid-template-columns: 1fr;
                .hotels-list-right {
                    padding-top: 0px;
                }
                .hotels-list-left {
                    padding-bottom: 0px;
                }
            }
        }
    }

    .loading-circle-box {
        width: 100% !important;
    }
}

.loading-circle-box {
    width: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-circle {
        animation: rotate 2s linear infinite;
        margin: 40px;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
