.loading_container {
    width: fit-content;
    animation: rotation 2s linear infinite;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
