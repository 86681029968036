.vertical-title {
    font-size: 8rem;
    text-transform: uppercase;
    rotate: 90deg;
    position: absolute;
    right: -320px;
    color: #a37826;

    border-bottom: 1px solid rgb(136, 136, 136);
}

.title-click {
    cursor: pointer;

    &:hover {
        color: white;
    }
}

// Queries

@media (max-width: 1250px) {
    .vertical-title {
        rotate: 0deg;
        top: -100px !important;
        right: auto !important;
        font-size: 5rem;
    }
}

@media (max-width: 500px) {
    .vertical-title {
        top: -60px !important;
    }
}
