// Main Component
.testimonials-container {
    height: 600px;
    position: relative;
    margin-top: 200px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .slick-slide {
        transform: scale(0.5);
        transition: all 0.3s linear;
    }

    // card is in center
    .slick-center {
        transform: scale(1);
        .testimonial-card {
            border: 1px solid goldenrod;
        }
    }
}

.testimonials-card-container {
    height: 100%;
    width: 100%;
}

.testimonials-redirect-text {
    .redirect-text {
        font-size: 1.4rem;
        text-decoration: underline;
        animation: glow 3s infinite;
        cursor: pointer;
    }
}

// Card Component
.testimonial-card {
    height: 460px;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .card-text {
        width: 90%;
        .text {
            text-align: center;
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    .testimonial-card-info {
        display: flex;
        flex-direction: column;

        .name,
        .company {
            text-align: center;
        }

        .name {
            font-size: 2.4rem;
            font-weight: bold;
        }

        .company {
            font-size: 1.6rem;
            border-top: 1px solid goldenrod;
            color: rgb(136, 136, 136);
        }
    }
}

@media (max-width: 900px) {
    .testimonial-card {
        .card-text {
            .text {
                font-size: 1.8rem;
                line-height: 3rem;
            }
        }
    }
}

@media (max-width: 400px) {
    .testimonials-redirect-text {
        .redirect-text {
            padding: 0 6rem;
            text-align: center;
        }
    }
}
