.overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    position: fixed;
    top: 0px;
    z-index: 120;

    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    flex-direction: column;
}

.loading_text {
    font-size: 20px;
    font-weight: bold;
    animation: glow 1s infinite;
}

@keyframes glow {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}
