.offer-search-tag {
    background: transparent;
    font-size: 1.6rem;
    color: white;
    padding: 2px 10px;
    cursor: pointer;
    border: none;

    &:hover, &:focus {
        color: rgb(135, 135, 135);
    }
}

.btnActive {
    color: rgb(135, 135, 135);
}