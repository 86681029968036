.enquiry-container {
    background: black;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .enquiry-main-container {
        width: 100%;
        height: 100%;
        padding: 40px 40px;

        display: flex;
        justify-items: center;
        align-items: center;

        .enquiry-main {
            background: url("../../images/enquiryForm.png");
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 100%;

            padding: 40px;

            display: flex;

            .enquiry-main-left,
            .enquiry-main-right {
                width: 60%;
                height: 100%;
            }

            .enquiry-main-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .logo {
                    font-family: "Shoreline";
                    font-size: 1.4rem;
                    margin-right: 60px;
                }

                .form-title {
                    font-size: 5rem;
                    font-weight: 700;
                    margin-right: 60px;
                }

                .contacts {
                    margin-right: 60px;
                    .contact-title {
                        font-size: 1.6rem;
                        font-weight: 700;
                    }
                    .contact {
                        font-size: 1.4rem;
                        a {
                            text-decoration: none;
                            color: white;

                            &:hover {
                                color: goldenrod;
                            }
                        }
                    }
                }
            }

            .enquiry-main-right {
                width: 100%;
                padding: 0px 30px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .enquiry-main-right-title {
                    text-align: center;
                    color: #b4b4b4;
                    font-size: 1.8rem;
                    font-weight: 700;
                }

                .text-area-div {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 4rem;

                    .enquiry-children-ages {
                        overflow-y: scroll;
                        height: 160px;
                        padding-right: 10px;
                        padding-bottom: 1px;

                        display: grid;
                        gap: 10px;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr 1fr 1fr;
                    }
                }

                .children-active {
                    display: none !important;
                }

                .message-active {
                    column-gap: 0px;
                    grid-template-columns: 1fr;
                }

                .enquiry-form-message-area {
                    min-width: 100%;
                    max-width: 100%;
                    resize: none;
                    background-color: transparent;
                    border: 1px solid #686868;
                    padding: 10px;
                    height: 160px;
                    color: white;
                    font-size: 1.4rem;

                    &:focus {
                        outline: none;
                    }
                }

                .enquiry-form-inputs-container {
                    display: flex;
                    flex-direction: column;
                    gap: 40px;

                    .enquiry-form-inputs {
                        .enquiry-form-input-title {
                            color: #a5a5a5;
                            font-size: 2rem;
                            font-weight: 500;
                        }

                        .enquiry-form-inputs-main {
                            margin-top: 30px;
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            column-gap: 40px;
                            row-gap: 16px;

                            .input-box {
                                height: 20px;
                                display: flex;
                                align-items: center;

                                .enquiry-input-label {
                                    font-size: 14px;
                                    white-space: nowrap;
                                    margin-right: 10px;
                                    color: gray;
                                    font-weight: 500;

                                    cursor: pointer;
                                }

                                .enquiry-input {
                                    width: 100%;
                                    background-color: transparent;
                                    border: none;
                                    border-bottom: 1px solid gray;
                                    color: white;
                                    padding-left: 2px;

                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }

                            // input {
                            //     font-size: 1.4rem;
                            //     background-color: transparent;
                            //     border: none;
                            //     border-bottom: 1px solid #686868;
                            //     padding-bottom: 6px;
                            //     color: white;

                            //     &:focus {
                            //         outline: none;
                            //     }
                            // }
                        }
                    }
                }

                .enquiry-form-buttons {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .tnc {
                        font-size: 1.2rem;
                        color: #b4b4b4;
                    }

                    .enquiry-form-buttons_container {
                        display: flex;
                        justify-content: flex-end;
                        gap: 20px;
                        button {
                            background-color: transparent;
                            color: white;
                            border: 1px solid #686868;
                            padding: 10px 20px;
                            cursor: pointer;
                            transition: all 0.16s linear;

                            &:hover {
                                background-color: rgb(67, 48, 0);
                            }
                        }

                        .submit {
                            background: rgb(50, 40, 12);
                        }
                    }
                }
            }
        }
    }
}

.l-circle {
    animation: rotate 2s linear infinite;
}

// Child Ages component
.child-age-box {
    display: flex;
    border: 1px solid gray;
    justify-content: center;
    align-items: center;
    height: 40px;

    .child-age-label {
        width: 100%;
        padding-left: 10px;
        font-size: 12px;
        color: gray;
    }

    .child-age-input {
        width: 30%;
        background-color: transparent;
        border: none;
        color: white;
        text-align: center;
        margin-right: 10px;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }

        &:focus {
            outline: none;
        }

        border-bottom: 1px solid gray;
    }
}

// For the QueryInput Component
.children-input_range_fix {
    height: 100%;
    position: absolute;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1px;

    .children-counter-button {
        height: 100%;
        width: 20px;
        user-select: none;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        background-color: rgb(36, 36, 36);
        border: 1px solid rgb(84, 84, 84);
        margin-bottom: 3px;
    }
}

@media (max-width: 1148px) {
    .enquiry-container {
        height: fit-content;
        .enquiry-main-container {
            .enquiry-main {
                flex-direction: column;
                gap: 40px;

                .enquiry-main-left {
                    width: 100%;

                    .logo {
                        display: none;
                    }

                    .form-title {
                        margin-right: 0px;
                        text-align: center;
                        font-size: 4rem;
                    }

                    .contacts {
                        visibility: hidden;
                    }
                }

                .enquiry-main-right {
                    height: 640px;
                    .enquiry-form-message-area {
                        min-height: 120px;
                    }
                }
            }
        }
    }
}

// Query to change child age input from taking 50% width to 100%
@media (max-width: 750px) {
    .enquiry-children-ages {
        grid-template-columns: 1fr !important;
    }
}

@media (max-width: 678px) {
    .enquiry-container {
        .enquiry-main-container {
            padding: 0px;
            .enquiry-main {
                gap: 20px;
                .enquiry-main-right {
                    padding: 0px;
                    .enquiry-form-inputs-container {
                        .enquiry-form-inputs {
                            .enquiry-form-inputs-main {
                                grid-template-columns: 1fr;
                                row-gap: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 608px) {
    .enquiry-container {
        .enquiry-main-container {
            .enquiry-main {
                .enquiry-main-right {
                    .enquiry-form-buttons {
                        button {
                            font-size: 1.2rem;
                        }
                    }
                    .text-area-div {
                        .enquiry-form-message-area {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .text-area-div {
        grid-template-columns: 1fr !important;
        row-gap: 40px;
        display: flex !important;
        flex-direction: column-reverse;
    }

    .enquiry-children-ages {
        grid-template-columns: 1fr 1fr !important;
        height: fit-content !important;
        max-height: 160px;
        grid-template-rows: 1fr !important;
    }

    .enquiry-container {
        .enquiry-main-container {
            .enquiry-main {
                .enquiry-main-right {
                    height: fit-content;
                    gap: 40px;

                    .enquiry-form-buttons {
                        button {
                            font-size: 1.2rem;
                            padding: 1.4rem 2rem !important;
                        }
                    }
                }
            }
        }
    }
}
