.offer-search-container {
    margin: 0 200px;

    .offer-search-form {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        form {
            width: 85%;
            .offer-search-main {
                width: 100%;
                margin-top: 60px;
                background: transparent;
                border: 1px solid white;
                border-right: none;
                padding: 10px;
                caret-color: white;
                color: white;
                letter-spacing: 2px;
                margin-bottom: 60px;
        
                &::placeholder {
                    color: rgb(181, 181, 181);
                }
            }
        
            .offer-search-main:focus {
                outline: none;
            }   
        }

        .offer-search-clear {
            width: 15%;
            height: fit-content;
            padding: 10px;
            letter-spacing: 2px;
            background: transparent;
            color: white;
            border: 1px solid white;
            display: flex;
            justify-content: center;
            align-items: center;
    
            &:hover {
                background-color: rgb(29, 29, 29);
                cursor: pointer;
            }

                .offer-search-clear-icon {
                    display: none;
                    align-items: center;
                }
        }
    }

    .offer-search-tag-container {
        margin-bottom: 60px;
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        flex-wrap: wrap;

        .offer-search-all-button {
            background: transparent;
            font-size: 1.6rem;
            color: white;
            padding: 2px 10px;
            cursor: pointer;
            border: none;
        
            &:hover, &:focus {
                color: rgb(135, 135, 135);
            }
        }
    }
}

.offer-card-container {
    margin: 0 200px;
    margin-bottom: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 120px;
}

@media (max-width: 1200px) {
    .offer-search-container {
        margin: 0px 50px;

        .offer-search-form {
            margin-top: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            form {
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;

                .offer-search-main {
                    width: 100%;
                }
              
            }
    
            .offer-search-clear {
                width: 20%;
            }
        }
    }

    .offer-card-container {
        margin: 0 50px;
        gap: 80px;
        margin-bottom: 50px;
    }
}

@media (max-width: 768px) {
    .offer-search-container {
        
        .offer-search-form {
    
            form {
                width: 70%;

                .offer-search-main {
                    font-size: 1.4rem;
                    padding: 6px;
                }
            }
    
            .offer-search-clear {
                width: 30%;
                font-size: 1.4rem;
                padding: 6px 4px;
            }
        }
    }

    .offer-card-container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 435px) {
    .offer-search-container {
        
        .offer-search-form {
    
            form {
                width: 90%;

                .offer-search-main {
                    font-size: 1.4rem;
                    padding: 6px;
                }
            }
    
            .offer-search-clear {
                width: 10%;
                color: rgb(191,191,191);
                font-size: 1.4rem;

                .offer-search-clear-text {
                    display: none;
                }

                .offer-search-clear-icon {
                    display: flex;
                    align-items: center;

                    .offer-search-clear-icon-main {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }
}