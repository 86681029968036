.country-name-box {
    .country-name {
        color: rgb(184, 184, 184);
        cursor: pointer;
        font-size: 2.2rem;
        width: fit-content;
        user-select: none;

        &:hover {
            color: white;
        }
    }

    .country-active {
        color: white;
    }
}

@media (max-width: 1320px) {
    .country-name-box {
        .country-name {
            font-size: 1.6rem;
        }
    }
}

@media (max-width: 1000px) {
    .country-name-box {
        .country-name {
            font-size: 1.8rem;
        }
    }
}

@media (max-width: 610px) {
    .country-name-box {
        .country-name {
            font-size: 2rem;
        }
    }
}
