.about-us-main {
    width: 80vw;
    margin: 0 auto;
    margin-top: 100px;
    height: fit-content;
    display: flex;
    gap: 100px;
    justify-content: space-around;

    .about-us-overlay {
        width: 100vw;
        height: 100vh;
        background-color: black;
        position: fixed;
        top: 0;
        opacity: 0.6;
        z-index: 1;
    }
}

.about-us-secondary {
    width: 100%;
    height: 600px;
    width: 80vw;
    margin: 0 auto;
    margin-top: 160px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .about-us-slider {
        width: 60%;
        height: 80%;
        border: 2px solid black;
        position: relative;

        .about-us-slider-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;

            p {
                font-family: "Shoreline";
                font-size: 3rem;
                position: absolute;
                bottom: 10px;
                right: 16px;
                color: #a37826;
            }
        }

        .about-us-team {
            height: 475px;
            width: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;

            text-align: center;
            font-size: 6rem;
            font-family: "Shoreline", cursive;
        }

        .team-one {
            color: white;
        }

        .team-two {
            color: goldenrod;
        }

        // .team-three {
        //     background: url("../../images/team-three.jpg");
        //     background-size: cover;
        //     background-position: center;
        //     background-repeat: no-repeat;
        // }
    }
}

.slick-next {
    right: -200px;
    transform: scale(3);
}

.slick-prev {
    left: -200px;
    transform: scale(3);
}

.slick-slide > div {
    margin: 0 1px;
}
.slick-list {
    margin: 0 -1px;
}

/* Queries */

@media (max-width: 1350px) {
    .about-us-main {
        flex-wrap: wrap;
    }

    .about-us-main:nth-child(2) {
        margin-bottom: 100px;
    }

    .slick-next {
        right: -100px;
    }

    .slick-prev {
        left: -100px;
    }
}

@media (max-width: 1000px) {
    .about-us-secondary {
        .about-us-slider {
            width: 80%;
            height: 60%;

            .about-us-team {
                height: 355px;
            }

            .about-us-slider-overlay {
                p {
                    font-size: 2.4rem;
                }
            }
        }
    }
}

@media (max-width: 750px) {
    .about-us-secondary {
        .about-us-slider {
            height: 40%;

            .about-us-team {
                height: 235px;
            }
        }
    }

    .slick-next {
        right: -60px;
        transform: scale(2);
    }

    .slick-prev {
        left: -60px;
        transform: scale(2);
    }
}

@media (max-width: 700px) {
    .about-us-secondary {
        .about-us-slider {
            .about-us-team {
                font-size: 4rem;
            }
        }
    }
}

@media (max-width: 450px) {
    .about-us-secondary {
        .about-us-slider {
            width: 100%;
        }
    }

    .slick-next {
        right: 100px;
        top: 300px;
    }

    .slick-prev {
        left: 100px;
        top: 300px;
    }
}
