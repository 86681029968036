.hotel-section-brands {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;

    .port-card {
        width: 30rem;
        height: 220px;
        background-color: white;
        margin: 30px 10px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        transition: all 700ms ease;
        border-radius: 10px;

        &:hover {
            height: 280px;

            .port-content {
                visibility: visible;
                opacity: 1;
                margin-top: -10px;
                transition-delay: 0.3s;
            }
        }

        .port-imgBx {
            width: 100%;
            height: 180px;
            z-index: 1;
            display: flex;

            background-size: contain !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
        }

        .port-content {
            position: relative;
            margin-top: -140px;
            padding: 10px 0;
            text-align: center;
            color: #111;
            visibility: hidden;
            opacity: 0;
            transition: 1s ease;
        }
    }
}

.button {
    width: 100%;
    height: 100%;
    border: 1px solid teal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: 400;
    border-radius: 1rem;
    padding: 1rem;
    cursor: pointer;
}
.dropdwon_icon {
    color: teal;
}

// Increasing Card width when device is mobile
@media (max-width: 470px) {
    .hotel-section-brands {
        .port-card {
            width: 40rem;
        }
    }
}
