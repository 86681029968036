.share_modal {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  animation: fade 1000ms ease forwards 1;
  .share_modal_wrapper {
    width: 35rem;
    height: auto;
    background-color: white;
    border-radius: 1rem;
    animation: jump 600ms ease forwards 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    .top_header {
      width: 100%;
      height: 5rem;
      border-bottom: 2px solid #a37826;
      display: flex;
      align-items: center;
      justify-content: center;
      h3 {
        font-size: 2.5rem;
        color: black;
        font-weight: 400;
      }
    }
    .container {
      width: 90%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin:0 auto;
      margin-bottom: 1rem;
      .share_platform_ {
        width: 9rem;
        height: 9rem;
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .copy {
          width: 5rem;
          height: 5rem;
          border: 1px solid blue;
          background-color: blue;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .copy_icon {
            font-size: 2.5rem;
            color: white;
          }
        }
        span {
          font-size: 1.3rem;
          color: black;
          font-weight: bolder;
          margin-top: 0.5rem;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }
    .url_div {
      width: 100%;
      height: auto;
      border: 1px solid grey;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem;
      font-size: 1.2rem;
      .left_input {
        width: 85%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        border: none;
        outline: none;
      }
      .right {
        width: 15%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .copy_icon {
          color: black;
          color: blue;
          font-size: 1.8rem;
          cursor: pointer;
          &:hover {
            color: rgb(6, 6, 133);
          }
        }
      }
      &:hover {
        box-shadow: 0 1px 2px 1px grey;
      }
    }
    .footer {
      width: 100%;
      height: auto;
      border-top: 2px solid goldenrod;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      h2 {
        font-size: 2rem;
        color: rgb(190, 139, 9);
        font-family: "Shoreline";
        font-weight: bolder;
      }
    }
    .close_modal {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      height: 4rem;
      width: 4rem;
      cursor: pointer;
      color: goldenrod;
      font-size: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: rgb(145, 105, 5);
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0.1;
  }
}
@keyframes jump {
  0% {
    transform: translateY(100rem);
  }
  25% {
    transform: translateY(-6rem);
  }
  75% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

@media screen and (max-width: 1050px) {
  .share_modal {
    .share_modal_wrapper {
      width: 45%;
      margin-top: 0rem;
      .container {
        .share_platform_ {
          .copy {
            width: 7rem;
            height: 7rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .share_modal {
    .share_modal_wrapper {
      width: 70%;
      margin-top: 20rem;
      .container {
        .share_platform_ {
          .copy {
            width: 7rem;
            height: 7rem;
          }
        }
      }
    }
  }
}
