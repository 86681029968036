.hotel-list {
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .hotel-destination {
        font-size: 1.8rem;
        color: rgb(212, 212, 212);
        cursor: pointer;
        width: fit-content;
        color: #a37826;

        &:hover {
            color: rgb(176, 176, 176);
        }
    }

    .hotel-name {
        font-size: 1.8rem;
        margin: 4px 0px;
        margin-left: 10px;
        cursor: pointer;
        color: rgb(176, 176, 176);
        width: fit-content;

        &:hover {
            color: #a37826;
        }
    }

    .new-tag {
        color: #ff1837;
        padding: 0.1rem 0.3rem;
        animation: glow 2s infinite;
        align-self: flex-start;

        font-size: 0.8rem;
        font-weight: bold;
        margin: 4px 0px 0px 6px;
    }
}

@keyframes glow {
    0% {
        opacity: 0.7;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}

@media (max-width: 1320px) {
    .hotel-list {
        .hotel-destination {
            font-size: 1.4rem;
        }
        .hotel-name {
            font-size: 1.4rem;
        }
    }
}

@media (max-width: 1000px) {
    .hotel-list {
        .hotel-destination {
            font-size: 1.6rem;
        }
        .hotel-name {
            font-size: 1.6rem;
        }
    }
}
