.country-list-box {
    margin-top: 18px;
    margin-bottom: 40px;

    display: flex;
    flex-direction: column;
    gap: 6px;
}

.country-without-city {
    .cwc-country-name {
        font-size: 2.2rem;
        font-weight: 400;
        color: rgb(184, 184, 184);
        cursor: pointer;
        user-select: none;
        width: fit-content;

        &:hover {
            color: white;
        }
    }

    .cwc-active {
        color: white;
    }

    .cwc-hotels-list {
        list-style: none;
        margin-bottom: 20px;
        margin-top: 6px;

        display: flex;
        flex-direction: column;
        gap: 4px;
        li {
            margin-left: 10px;
            font-size: 1.8rem;
            color: rgb(184, 184, 184);
            cursor: pointer;
            width: fit-content;
            display: flex;

            &:hover {
                color: rgb(151, 113, 47);
                span {
                    color: rgb(255, 207, 176);
                }
            }

            .new-tag {
                color: #ff1837 !important;
                padding: 0.1rem 0.3rem;
                animation: glow 2s infinite;
                align-self: flex-start;
                font-size: 0.8rem;
                font-weight: bold;
                margin: 2px 0px 0px 6px;
            }

            span {
                color: #b3832b;
            }
        }
    }
}

@media (max-width: 1320px) {
    .country-without-city {
        .cwc-country-name {
            font-size: 1.6rem;
        }
        .cwc-hotels-list {
            li {
                font-size: 1.4rem;
            }
        }
    }
}

@media (max-width: 1000px) {
    .country-without-city {
        .cwc-country-name {
            font-size: 1.8rem;
        }
        .cwc-hotels-list {
            li {
                font-size: 1.6rem;
            }
        }
    }
}

@media (max-width: 610px) {
    .country-without-city {
        .cwc-country-name {
            font-size: 2rem;
        }
    }
}
