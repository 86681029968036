.portfolio {
    margin-bottom: 10rem; //! May need to remove

    .portfolio-landing-page {
        width: 100%;
        height: 100vh;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .portfolio-landing-page-wrapper {
            height: 80%;
            width: 95rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding-top: 5rem;

            .heading {
                font-family: "Shoreline";
                font-size: 9.5rem;
            }

            .portfolio-page-para {
                font-size: 2rem;
                letter-spacing: 2px;
                text-align: center;
                margin-top: 3rem;
                line-height: 3.5rem;
            }
        }
    }

    .search-by {

        width: 60%; //! was 944px before
        margin: 3rem auto;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .buttons {
            
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .search-btn {
                width: 40%;
                height: 100%;
                border-radius: 1rem;
                color: white;
                font-size: 1.5rem;

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
                border: 1px solid white;
                background: transparent;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            .search-btn-active {
                background-color: white;
                color: black;
            }
        }

        .portfolio-search {
            width: 300px;
            height: 100%;
            position: relative;

            input {

                width: 100%;
                height: 100%;
                text-align: center;
                font-size: 1.5rem;
                border-radius: 1rem;
                border: 1px solid white;
                background-color: transparent;
                color: white;
            }

            .portfolio-search-cross-btn {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;

                display: flex;
                justify-content: flex-end;
                align-items: center;
                visibility: hidden;

                svg {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                    visibility: visible;

                    cursor: pointer;
                    &:hover {
                        color: goldenrod;
                    }
                }
            }
        }
    }

    .cards-container {
        height: fit-content;
        margin-top: 100px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .cards-container-main {
            height: fit-content; //! needs to be fit-content
            width: 80%;
            overflow: hidden;
        }
    }
}

@media (max-width: 1200px) {
    .portfolio {

        .search-by {
            width: 90%;
        }

        .cards-container {
            .cards-container-main {
                width: 100%;
            }
        }
    }
}

@media (max-width: 850px) {
    .portfolio {
        .portfolio-landing-page {
            .portfolio-landing-page-wrapper {
                width: 90%;
            }
        }
    }
}

@media (max-width: 750px) {
    .portfolio {

        .search-by {
            flex-direction: column-reverse;
            height: 120px;
            gap: 20px;

            .buttons {
                width: 400px;
                gap: 20px;

                .search-btn {
                    width: 50%;
                }
            }

            .portfolio-search {
                width: 400px;   
            }
        }
    }
}

@media (max-width: 450px) {
    .portfolio {
        .portfolio-landing-page {
            .portfolio-landing-page-wrapper {
                .heading {
                    font-size: 8rem;
                }
                .portfolio-page-para {
                    text-align: justify;
                }
            }
        }

        .search-by {
            .buttons {
                width: 300px;
            }

            .portfolio-search {
                width: 300px;
            }
        }
    }
}

// .portfolio {
//     margin-bottom: 10rem;
//     // position: relative;
//     overflow: hidden;
//     .portfolio_landing_page {
//         // border: 1px solid red;
//         width: 100%;
//         height: 100vh;
//         // height: calc(100vh - 8rem);
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//         // margin-top: 8rem;
//         .portfolio_landing_page_wrapper {
//             height: 80%;
//             width: 95rem;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: space-around;
//             padding-top: 5rem;
//             // border: 1px solid red;
//             .heading {
//                 font-family: "Shoreline";
//                 font-size: 9.5rem;
//             }
//             .portfolio_page_para {
//                 font-size: 2rem;
//                 letter-spacing: 2px;
//                 text-align: center;
//                 margin-top: 3rem;
//                 line-height: 3.5rem;
//             }
//         }
//     }
//     .search_by {
//         width: 944px;
//         margin: 3rem auto;
//         height: 5rem;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         
//         .buttons {
//             width: 50%;
//             height: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
//             .btns {
//                 width: 40%;
//                 height: 100%;
//                 // background-color: var(--border-golden);
//                 border-radius: 1rem;
//                 color: white;
//                 font-size: 1.5rem;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 cursor: pointer;
//                 border: none;
//                 border: 1px solid white;
//                 background: transparent;
//                 text-transform: uppercase;
//                 letter-spacing: 2px;
//                 font-weight: 400;
//                 // &:hover {
//                 // background-color: rgba(218, 165, 32, 0.87);
//                 // }
//             }
//             .active {
//                 background-color: white;
//                 color: black;
//                 border: 1px solid black;
//             }
//         }
//         .portfolio_search {
//             height: 100%;
//             width: 300px;
//             position: relative;
//             input {
//                 width: 300px;
//                 height: inherit;
//                 text-align: center;
//                 font-size: 1.5rem;
//                 border-radius: 1rem;
//                 border: 1px solid white;
//                 background-color: transparent;
//                 color: white;
//             }

//             .portfolio-search-cross-icon {
//                 position: absolute;
//                 cursor: pointer;
//                 right: 12px;
//                 top: 15px;
//                 width: 20px;
//                 height: 20px;
//                 color: gray;
//             }
//         }
//     }
//     &::-webkit-scrollbar {
//         appearance: none;
//     }
// }

// @media screen and (max-width: 1000px) {
//     .portfolio {
//         .search_by {
//             width: 870px;
//             height: auto;
//             padding: 0 2rem;
//             .buttons,
//             .portfolio_search {
//                 height: 4rem;

//                 .portfolio-search-cross-icon {
//                     top: 9px;
//                     width: 16px;
//                     height: 16px;
//                 }
//             }
//         }
//     }
// }

// @media (max-width: 874px) {
//     .portfolio {
//         .search_by {
//             width: 580px;
//             height: auto;
//             padding: 0 2rem;
//             padding-left: 0rem;
//             .portfolio_search {
//                 height: 4rem;
//                 width: 200px;
//                 input {
//                     width: 200px;
//                 }

//                 .btns {
//                     font-size: 1.2rem;
//                 }

//                 .portfolio-search-cross-icon {
//                     top: 11px;
//                     right: 10px;
//                     width: 12px;
//                     height: 12px;
//                 }
//             }

//             .buttons {
//                 height: 4rem;
//                 width: 340px;
//                 justify-content: space-around;
//             }
//         }
//     }
// }

// @media (max-width: 610px) {
//     .portfolio {
//         .search_by {
//             width: 490px;
//             height: auto;
//             padding: 0 2rem;
//             .buttons,
//             .portfolio_search {
//                 height: 4rem;

//                 .btns {
//                     font-size: 1.2rem;
//                 }

//                 .portfolio-search-cross-icon {
//                     top: 7px;
//                     right: 10px;
//                     width: 14px;
//                     height: 14px;
//                 }
//             }
//         }
//     }
// }

// @media (max-width: 491px) {
//     .portfolio {
//         .search_by {
//             width: 260px;
//             height: auto;
//             padding: 0 2rem;
//             flex-direction: column-reverse;
//             gap: 4rem;
//             .portfolio_search {
//                 width: 280px;
//                 padding-bottom: 5rem;

//                 input {
//                     width: 280px;
//                     height: 50px;
//                 }

//                 .portfolio-search-cross-icon {
//                     top: 18px;
//                     right: 12px;
//                     width: 14px;
//                     height: 14px;
//                 }
//             }
//             .buttons {
//                 height: 50px;
//                 width: 410px;
//                 justify-content: center;
//                 gap: 30px;
//                 .btns {
//                     font-size: 1.4rem;
//                     width: 30%;
//                 }
//             }
//         }
//     }
// }

// @media screen and (max-width: 480px) {
//     .portfolio {
//         .portfolio_landing_page {
//             margin-bottom: 8rem;
//             .portfolio_landing_page_wrapper {
//                 width: 90%;
//                 .heading {
//                     font-size: 7rem;
//                     font-weight: 300;
//                 }
//                 .portfolio_page_para {
//                     font-size: 2.2rem;
//                     // padding: 0 4rem;
//                     text-align: justify;
//                 }
//             }
//         }
//     }
// }
