@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

.offer-card {
    width: 100%;
    border: 1px solid rgb(163, 163, 163);
    height: fit-content;
    font-family: "Poppins", sans-serif;
    overflow: hidden;

    .offer-card-name-container {
        height: 100px;
        border-bottom: 1px solid rgb(92, 92, 92);
        display: flex;
        align-items: center;
        justify-content: center;

        .offer-card-name {
            font-size: 5.6rem;
            font-weight: 900;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .offer-card-image-container {
        height: 100px;
        border-bottom: 1px solid rgb(92, 92, 92);
        background-size: "cover";
        display: flex;
        align-items: center;

        p {
            font-size: 26px;
            width: 100%;
            padding: 0 10px;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 2px;
            font-weight: 700;
            color: #c6cfcf;
        }
    }

    .offer-card-desc-container {
        overflow: hidden;
        height: 102px;
        font-weight: 400;
        font-size: 1.6rem;
        padding: 20px;

        b {
            color: #a37826;
        }

        ul {
            padding-left: 15px;
        }

        .offer-card-date-tr {
            margin-top: 20px;
        }
    }

    .offer-card-desc-container-active {
        height: fit-content;
    }

    .offer-card-animate-button-container {
        padding: 20px 0;
        font-size: 1.8rem;
        display: flex;
        align-items: center;

        .offer-card-animate-button {

            display: flex;
            align-items: center;
            padding-left: 20px;

            &:hover {
                color: #a37826;
                cursor: pointer;
            }

            .offer-card-animate-icon {
                margin-left: 4px;
            }
        }
    }
}

@media (max-width: 1484px) {
    .offer-card {

        .offer-card-name-container {

            .offer-card-name {
                font-size: 4rem;
            }
        }
    }
}

@media (max-width: 1260px) {
    .offer-card {

        .offer-card-name-container {

            .offer-card-name {
                font-size: 3.4rem;
            }
        }
    }
}

@media (max-width: 475px) {
    .offer-card {

        .offer-card-name-container {
    
            .offer-card-name {
                font-size: 5rem;
            }
        }

        .offer-card-image-container {
            height: 120px; 

            p {
                font-size: 20px;
                width: 100%;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 2px;
                font-weight: 700;
                color: #c6cfcf;
            }
        }
    }
}

@media (max-width: 405px) {
    .offer-card {
        .offer-card-name-container {
            .offer-card-name {
                font-size: 4.6rem;
            }
        }
    }
}

// .offer-card {
//     width: 100%;
//     height: 400px; // Could be problem
//     background-color: rgba(255, 255, 255, 0.26);
//     background: linear-gradient(
//         90deg,
//         rgba(10, 10, 10, 0.136029411764706) 56%,
//         rgba(255, 255, 255, 0.0897934173669467) 100%
//     );
//     margin-bottom: 60px;
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr;

//     .offer-card-section {
//         border: 0.1px solid white;
//         width: 100%;
//         overflow: hidden;

//         .offer-card-section-image {
//             width: 100%;
//             height: 70%;
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             div {
//                 background-color: #fff;
//                 width: 100%;
//                 height: 100%;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;

//                 img {
//                     width: 80%;
//                     height: 80%;
//                 }
//             }
//         }

//         .offer-card-hotel-name {
//             height: 50%;
//             width: 100%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             border-bottom: 1px solid white;

//             p {
//                 font-size: 26px;
//                 text-transform: uppercase;
//                 letter-spacing: 2px;
//                 font-weight: 700;
//                 color: #c6cfcf;
//                 width: 80%;
//                 margin: 0 auto;
//                 text-align: center;
//             }
//         }

//         .offer-name-container {
//             display: flex;
//             width: 400px;
//             height: 100%;
//             justify-content: center;
//             align-items: center;
//             flex-direction: column;
//             // border: 1px solid white; // Temp

//             .offer-name-container-first,
//             .offer-name-container-second {
//                 width: 400px;
//                 height: 50%;
//                 display: flex;
//                 justify-content: center;

//                 h3 {
//                     font-size: 80px;
//                     text-transform: uppercase;
//                     letter-spacing: 4px;
//                     height: fit-content;
//                     font-weight: 900;
//                 }
//             }

//             .offer-name-container-first {
//                 align-items: flex-end;
//             }

//             .offer-name-container-second {
//                 align-items: flex-start;
//             }
//         }

//         .offer-card-section-dates {

//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;
//             gap: 10px;

//             .date-travel,
//             .date-booking {
//                 height: 50%;
//                 width: 100%;

//                 .dates-heading {
//                     text-align: center;
//                     font-size: 16px;
//                     padding-top: 30px;
//                     letter-spacing: 4px;
//                     color: rgb(160, 160, 160);
//                 }

//                 .dates-heading-second {
//                     text-align: center;
//                     font-size: 16px;
//                     padding-top: 10px;
//                     letter-spacing: 4px;
//                     color: rgb(160, 160, 160);
//                 }

//                 .dates-info {
//                     font-size: 20px;
//                     text-align: center;
//                     margin-top: 10px;
//                     color: rgb(200, 200, 200);
//                 }
//             }

//             p {
//                 font-family: sans-serif;
//                 font-size: 14px;
//             }

//             .offer-card-section-desc-inner {
//                 ul {
//                     padding-left: 20px;

//                     li {
//                         margin-bottom: 6px;
//                     }
//                 }

//                 b {
//                     color: gold;
//                 }

//                 font-weight: 400;
//                 font-size: 14px;
//                 letter-spacing: 1px;
//                 line-height: 18px;
//                 color: #ddd;
//                 margin: 0 20px;
//                 margin-top: 20px;
//             }
//         }

//         .offer-card-section-desc {
//             height: fit-content;

//             .offer-read-btn {
//                 color: gold;
//             }

//             .offer-read-btn {
//                 font-size: 14px;
//                 padding: 15px 20px;
//                 display: flex;
//                 align-items: center;
//                 cursor: pointer;
//                 display: none;

//                 span {
//                     margin-top: 4px;
//                     margin-left: 8px;
//                 }
//             }

//             .offer-read-btn:hover {
//                 color: gold;
//             }

//             .date-travel,
//             .date-booking {
//                 height: 50%;
//                 width: 100%;

//                 .dates-heading {
//                     text-align: center;
//                     font-size: 16px;
//                     padding-top: 30px;
//                     letter-spacing: 4px;
//                 }

//                 .dates-heading-second {
//                     text-align: center;
//                     font-size: 16px;
//                     padding-top: 10px;
//                     letter-spacing: 4px;
//                 }

//                 .dates-info {
//                     font-size: 20px;
//                     text-align: center;
//                     margin-top: 10px;
//                 }
//             }

//         }
//     }

//     .offer-card-middle {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         border-right: none;
//     }

//     .offer-card-end {
//         border-left: none;
//     }
// }

// /*
//     Animations
// */

// @media (max-width: 1048px) {
//     .offer-card {
//         display: flex;
//         flex-direction: column;
//         height: fit-content;

//         .offer-card-section {
//             .offer-card-section-desc {
//                 .offer-read-btn {
//                     display: flex;

//                     span {
//                         margin-left: 0px;
//                     }
//                 }
//             }
//         }

//         .offer-card-middle {
//             border-right: 1px solid white;
//             border-bottom: none;
//         }

//         .offer-card-end {
//             border-left: 1px solid white;
//             border-top: none;

//             .offer-card-section-desc-inner {
//                 padding-bottom: 16px;
//             }
//         }

//         .offer-card-first {
//             .offer-card-hotel-name {
//                 height: 200px;
//                 background-position: center;
//             }

//             .offer-card-section-dates {
//                 height: fit-content;

//                 .date-booking {
//                     .dates-info {
//                         padding-bottom: 20px;
//                     }
//                 }
//             }
//         }
//     }
// }

// // @media (max-width: 768px) {
// //     .offer-card {
// //         .offer-card-section {
// //             .offer-card-section-desc {
// //                 p {
// //                     display: flex;
// //                     margin-bottom: 15px;
// //                 }
// //             }
// //         }
// //     }
// // }

// @media (max-width: 430px) {
//     .offer-card {
//         .offer-card-section {
//             .offer-name-container {
//                 .offer-name-container-first,
//                 .offer-name-container-second {
//                     h3 {
//                         font-size: 60px;
//                     }
//                 }
//             }

//             .offer-card-section-dates {
//                 .date-travel,
//                 .date-booking {
//                     .dates-heading {
//                         font-size: 14px;
//                     }

//                     .dates-heading-second {
//                         font-size: 14px;
//                     }

//                     .dates-info {
//                         font-size: 16px;
//                     }
//                 }
//             }
//         }
//     }
// }

// .offers_card {
//     width: 480px;
//     background-color: rgba(255, 255, 255, 0.26);
//     background: linear-gradient(90deg, rgba(0,0,0,0.9136029411764706) 56%, rgba(255,255,255,0.1097934173669467) 100%);
//     border: 1px solid white;
//     border-radius: 1rem;
//     color: white;
//     margin-bottom: 3rem;
//     padding: 1.5rem;
//     height: 250px;
//     display: flex;
//     align-items: center;
//     // &:hover {
//     //     transform: scale(1.03);
//     // }

//     .hotel_card_container {
//         width: 100%;
//     }

//     .hotel_name {
//         width: 100%;
//         height: fit-content;
//         display: flex;
//         align-items: center;
//         text-transform: capitalize;
//         justify-content: center;
//         margin-bottom: 10px;
//         position: relative;
//         .img {
//             width: 5rem;
//             height: 5rem;
//             border: 2px solid white;
//             border-radius: 50%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             position: absolute;
//             left: 0px;
//             top: 0px;
//             img {
//                 width: 40px;
//                 height: 40px;
//                 background-color: white;
//                 border-radius: 50%;
//                 object-fit: cover;
//             }
//         }
//         .hotel_ {
//             height: 100%;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             margin-left: 1.2rem;
//             h3 {
//                 font-size: 2.4rem;
//                 text-transform: uppercase;
//                 color: gold;
//                 margin: 0 auto;
//                 margin-top: 10px;
//                 letter-spacing: 2px;
//             }
//         }
//     }
//     .offer_details {

//         hr {
//             height: 1px;
//             border: none;
//             background: white;
//             opacity: .6;
//             margin-top: -2px;
//             margin-bottom: 8px;
//         }

//         .offer_name {
//             font-size: 1.8rem;
//             margin-bottom: 0.2rem;
//             letter-spacing: 0.4px;
//             text-transform: capitalize;
//             text-align: center;
//             font-weight: 500;
//             margin-top: 20px;
//             color: gold;
//         }
//         .room_category {
//             font-size: 1.2rem;
//             color: rgba(255, 255, 255, 0.815);
//             letter-spacing: 0.4px;
//             margin-bottom: 1rem;
//             text-transform: capitalize;
//             font-weight: 400;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             display: -webkit-box;
//             -webkit-line-clamp: 1; /* number of lines to show */
//             -webkit-box-orient: vertical;

//             span {
//                 font-weight: bold;
//             }
//         }
//         .offer_detail {
//             font-size: 1.4rem;
//             font-weight: 400;
//             line-height: 20px;
//             color: whitesmoke;
//             margin-bottom: 1.2rem;
//             height: fit-content;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             display: -webkit-box;
//             -webkit-line-clamp: 1; /* number of lines to show */
//             -webkit-box-orient: vertical;

//             ul {
//                 margin-left: 20px;
//             }
//         }

//         .dates_container {
//             display: flex;
//             align-items: center;
//             margin-top: 10px;
//             margin-bottom: 10px;
//         }
//         .dates {
//             font-size: 1.4rem;
//             color: rgba(255, 255, 255, 0.815);
//             font-weight: 700;
//             color: white;
//             letter-spacing: 0.4px;
//             margin-bottom: 0.3rem;

//             span {
//                 color: rgb(240, 240, 240);
//                 font-size: 1.2rem;
//                 font-weight: 400;;
//             }
//         }

//         .date-travel {
//             margin-bottom: 0rem;
//         }

//         .more_details {
//             font-size: 1.4rem;
//             background-color: transparent;
//             margin-top: 1.6rem;
//             color: gold;
//             font-weight: 500;
//             display: flex;
//             align-items: center;
//             border: none;
//             cursor: pointer;
//             .arrow {
//                 margin-left: 1rem;
//             }
//         }
//     }
// }

// @keyframes shrink {
//     0% {
//         transform: scale(1);
//     }
//     100% {
//         transform: scale(0.09);
//     }
// }

// @media screen and (max-width: 1050px) {
//     .offers_card {
//         width: 44%;
//     }
// }

// @media screen and (max-width: 800px) {
//     .offers_card {
//         width: 90%;
//     }
// }

// @media screen and (max-width: 450px) {
//     .offers_card {
//         width: 90%;
//         height: 210px;
//         .hotel_name {
//             width: 100%;
//             height: 7rem;
//             display: flex;
//             align-items: center;
//             text-transform: capitalize;
//             .img {
//                 width: 5rem;
//                 height: 5rem;
//                 border: 2px solid white;
//                 border-radius: 50%;
//                 img {
//                     width: 100%;
//                     height: 100%;
//                     border-radius: 50%;
//                     object-fit: cover;
//                 }
//             }
//             .hotel_ {
//                 h3 {
//                     font-size: 1.2rem;
//                 }
//                 p {
//                     font-size: 1.4rem;
//                     margin-top: 0.3rem;
//                     letter-spacing: 2px;
//                 }
//             }
//         }
//         .offer_details {
//             .offer_name {
//                 font-size: 2rem;
//             }
//             .room_category {
//                 font-size: 1.5rem;
//             }
//             .offer_detail {
//                 font-size: 2rem;
//             }
//             .dates {
//                 font-size: 1.4rem;
//             }
//             .more_details {
//                 font-size: 1.7rem;
//             }
//         }
//     }
// }

// @keyframes offer_card_length {
//     from {
//         height: fit-content;
//     }

//     to {
//         height: fit-content;
//     }
// }

// @keyframes offer_card_decrease {
//     from {
//         height: fit-content;
//     }

//     to {
//         height: fit-content;
//     }
// }

// @media (max-width: 450px) {
//     @keyframes offer_card_length {
//         from {
//             height: fit-content;
//         }

//         to {
//             height: fit-content;
//         }
//     }

//     @keyframes offer_card_decrease {
//         from {
//             height: fit-content;
//         }

//         to {
//             height: fit-content;
//         }
//     }
// }

// .webkit_animation {
//     overflow: visible;
//     -webkit-line-clamp: 100 !important;
// }
