.st-intro-section {
    height: 700px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.intro-main-container {
    height: 80%;
    overflow: hidden;
    transform-style: preserve-3d;
}

.wrapper {
    display: flex;
    height: 100%;
    width: 80%;
    margin: 0 auto;
    transform: perspective(400px) rotateX(27deg);
}

.intro-main-container::after {
    content: "";
    position: fixed;
    top: 0;
    width: 100%;
    height: 20%;

    // Fadding effect on top of scrolling text
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 10) 40%,
        rgba(0, 0, 0, 0) 100%
    );
}

.scroll-text {
    color: #a37826;
    line-height: 1.5em;
    font-weight: bold;
    text-align: center;
    position: relative;
    animation: scroll 40s linear forwards 2s infinite;
    margin-top: 16rem;

    // makes the text responsive
    font-size: 4rem;

    // Number Badge
    .intro-number {
        max-height: 50px;
        max-width: 50px;
        border-radius: 50%;
        border: 2px solid #a37826;
        margin: 0 auto;

        p {
            text-align: center;
            margin-top: -10px;
        }
    }
}

// Seperating line
.st-intro-separator {
    height: 1px;
    width: 80%;
    background: rgb(160, 160, 160);
}

// intro secondary
.intro-secondary-container {
    width: 80%;

    display: flex;
    justify-content: center;

    .text {
        font-size: 2rem;

        .logo {
            font-size: 2rem;
            font-family: "Shoreline";
        }
    }
}

// scroll animation
@keyframes scroll {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-240rem);
    }
}

/** Secondary Info **/
@media (max-width: 1103px) {
    .intro-secondary-container {
        .text {
            font-size: 1.8rem;
            .logo {
                font-size: 1.8rem;
            }
        }
    }
}

@media (max-width: 874px) {
    .intro-secondary-container {
        .text {
            font-size: 1.6rem;
            .logo {
                font-size: 1.6rem;
            }
        }
    }
}

@media (max-width: 777px) {
    .intro-secondary-container {
        .text {
            text-align: center;
        }
    }
}
/** Secondary Info **/

@media (max-width: 690px) {
    .st-intro-section {
        height: 600px;
    }

    @keyframes scroll {
        0% {
            transform: translateY(0px);
        }
        100% {
            transform: translateY(-280rem);
        }
    }
}

@media (max-width: 455px) {
    .scroll-text {
        font-size: 3.8rem;
    }

    @keyframes scroll {
        0% {
            transform: translateY(0px);
        }
        100% {
            transform: translateY(-320rem);
        }
    }
}

@media (max-width: 400px) {
    .scroll-text {
        font-size: 3.8rem;
        line-height: 6rem;
    }

    @keyframes scroll {
        0% {
            transform: translateY(0px);
        }
        100% {
            transform: translateY(-340rem);
        }
    }
}
