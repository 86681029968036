.btn {
    position: relative;
    width: 70px;
    height: 50px;
    border-radius: 10px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    z-index: 100;
    cursor: pointer;
}

.btn span {
    position: absolute;
    /* width: 40px; */
    height: 3px;
    background-color:#a37826;
    transition: 1s;
    border-radius: 10px;
}

/* .btn:hover span {
    background-color: gold;
} */

.btn11 {
    transform: translateY(-15px);
    width: 25px;
    left: 15px;
}

.btn22 {
    transform: translateY(15px);
    width: 15px;
    left: 15px;
}


.btn3 {
    width: 40px;
    transform: translateX(14rem);
    transition: 200ms;
    display: none;
}

.btn1 {
    width: 40px;
    transform: rotate(45deg);
    transition: 200ms;
}

.btn2 {
    width: 40px;
    transform: rotate(-45deg);
    transition: 200ms
}

.btn33 {
    width: 40px;
}

@media screen and (max-width:480px){
    .btn{
        height: 8rem;
    }
}