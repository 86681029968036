.coming_soon {
    width: 100%;
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    h1 {
      color: white;
      font-size: 8rem;
      font-family: "Shoreline";
      color: goldenrod;
      margin-top: 5rem;
      text-align: center;
      animation: comingsoon 3000ms ease infinite alternate-reverse;
    }
    button {
      background-color: transparent;
      width: 20rem;
      height: 5rem;
      border: 1.5px solid white;
      color: white;
      font-size: 2.5rem;
      padding: 0rem 3rem;
      text-transform: uppercase;
      border-radius: 6rem;
      letter-spacing: 3px;
      margin-top: 9rem;
      transition: width 300ms linear;
      &:hover {
        width: 21rem;
      }
    }
  }
  
  @keyframes comingsoon {
    0% {
      text-shadow: 0 2px 30px rgb(87, 62, 1);
    }
  }
  
  @media screen and (max-width: 480px) {
    .coming_soon {
      height: 90vh;
    }
  }
  