//***************************************************************** */ Main Component

.search_container {
    position: relative;
    cursor: pointer;

    // When hovered on Search icon

    .search_bar_container_active {
        input {
            width: 260px;
            padding: 0px 12px;
            border: 1px solid #3d3d3d;
        }

        .cross_icon {
            display: inline-block;
        }

        .searched_hotels {
            height: fit-content;
            display: inline-block;
            max-height: 200px;
            background-color: black;
            border: 1px solid #3d3d3d;
            width: 260px;
            overflow-y: scroll;

            .hotel {
                display: inline-block;
            }

            .not_found {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #898989;
                // Container is 200px in height
                // but using 198px here to disappear the container's scroll bar
                height: 198px;
                padding: 10px;

                .not_found__text {
                    font-size: 16px;
                    text-align: center;
                }

                .not_found__graphic {
                    font-size: 68px;
                    text-align: center;
                }
            }
        }
    }

    // .search_icon {
    //     animation: jump 0.3s;
    // }
}

.search_bar_container {
    position: absolute;
    top: 0px;
    left: 50px;
    background-color: black;

    // Default Search Bar Styles
    input {
        height: 40px;
        width: 0px;
        border: none;
        color: white;
        background-color: black;
        transition: all 1s ease-in-out;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: #898989;
        }
    }

    .searched_hotels {
        max-height: 200px;
        width: 0px;
        margin-top: 20px;
        transition: all 1s ease-in-out;

        .hotel {
            display: none;
        }

        .not_found {
            display: none;
        }
    }
}

.cross_icon {
    display: none;
    position: absolute;
    right: 10px;
    top: 14px;

    &:hover {
        color: goldenrod;
    }
}

//***************************************************************** */ Searched Hotels Component

.hotel {
    width: 100%;
    padding: 10px 10px;
    height: 80px;
    border-bottom: 1px solid #3d3d3d;

    display: flex;
    align-items: center;

    p {
        font-size: 14px;
    }

    .hotel_name {
        font-weight: bold;
        color: #898989;
    }

    .hotel_destination {
        color: #ff9900;
    }

    &:hover {
        background-color: rgb(15, 15, 10);
    }
}

.search_icon {
    border-radius: 2px;
    color: #a37826;
    animation: glow 2s infinite;
}

// Queries

@media (max-width: 1200px) {
    .search_bar_container {
        position: fixed;
        top: 80px;
        left: 0px;
        transform: translateX(calc(50vw - 50%));
        display: flex;
        flex-direction: column;
    }
}

// Queries

@keyframes glow {
    0% {
        filter: drop-shadow(0px 5px 12px #a37726);
        color: #a37826;
    }

    50% {
        filter: drop-shadow(0px 0px 6px #ffa600);
        color: #edbf6b;
    }

    100% {
        filter: drop-shadow(0px 5px 12px #a37726);
        color: #a37826;
    }
}

@keyframes jump {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}
