.about-us-container {
    width: 340px;
    height: 560px;
    border-radius: 200px;
    overflow: hidden;
    background: rgb(200, 200, 200);
    transition: all 1s;
    opacity: 0.6;

    .about-us-profile {
        width: 80%;
        height: 300px;
        border-radius: 200px 200px 100px 100px;
        margin: 0 auto;
        margin-top: 36px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        transition: all 1s;

        .about-us-profile-info-name {
            font-size: 20px;
            font-weight: 500;
            margin-top: 20px;
            text-align: center;
            color: #c6cfcf;
        }

        .about-us-profile-info-desc {
            font-size: 16px;
            text-align-last: center;
            margin-bottom: 18px;
            color: #c6cfcf;
        }
    }

    .about-us-details {
        margin: 10%;

        p {
            font-size: 16px;
            line-height: 20px;
            color: rgb(10, 10, 10);
            text-align: justify;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }

    .about-us-read {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 70px;

        button {
            border: none;
            border-radius: 40px 40px 50% 50%;
            color: rgb(255, 255, 255);
            padding: 20px 60px;
            padding-bottom: 40px;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            background: rgb(52, 52, 52);
        }

        button:hover {
            background: rgb(100, 100, 100);
            color: white;
        }
    }
}

// For Animation

.about-us-container-active {
    border-radius: 20px;
    overflow: hidden;
    height: fit-content;
    transition: all 1s;
    opacity: 1;

    .about-us-profile {
        border-radius: 20px 20px 20px 20px;
        transition: all 1s;
    }

    .about-us-details {
        p {
            -webkit-line-clamp: 100;
        }
    }

    .about-us-read {
        button {
            margin-bottom: 20px;
        }

        button:hover {
            background: rgb(94, 83, 54);
            color: white;
        }
    }
}
