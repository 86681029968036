.feedback-form-container {
    margin: 0 150px;
    margin-top: 20px;

    .feedback-form-tag {
        margin-bottom: 60px;
        .feedback-form-title {
            text-transform: uppercase;
            margin-top: 60px;
            font-size: 3rem;
            text-align: center;
            color: #a37826;
        }

        .feedback-form {
            height: 500px;
            margin-top: 60px;
            display: grid;
            grid-template-columns: 1fr 1fr 0.1fr;
            gap: 40px;

            .feedback-form-message {
                .feedback-form-message-container {
                    background-color: white;
                    border-radius: 12px;
                    height: 100%;
                    width: 100%;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .feedback-form-message-title {
                        color: black;
                        font-size: 2rem;
                        cursor: pointer;
                    }

                    .feedback-form-message-text {
                        width: 100%;
                        height: 90%;
                        padding: 20px;
                        border-radius: 12px;
                        font-size: 1.6rem;
                        resize: none;
                        border: 2px solid black;

                        &:focus {
                            outline: none;
                            border-color: #a37826;
                        }
                    }
                }
            }

            .feedback-form-input {
                border: 2px solid white;
                border-radius: 12px;

                .feedback-form-input-container {
                    padding: 40px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    input {
                        width: 100%;
                        padding: 20px;
                        font-size: 2rem;
                        background: black;
                        border: 2px solid white;
                        border-radius: 12px;
                        color: white;

                        &::placeholder {
                            color: rgb(194, 194, 194);
                        }

                        &:focus {
                            outline: none;
                            border-color: #a37826;
                        }
                    }

                    .feedback-form-input-message::-webkit-outer-spin-button,
                    .feedback-form-input-message::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }

            .feedback-form-submit-btn {
                height: 60px;
                align-self: flex-end;
                border-radius: 12px;
                cursor: pointer;
                width: 60px;
                background-color: transparent;
                border: 2px solid white;

                &:hover {
                    background-color: rgb(24, 24, 24);

                    .feedback-form-submit-icon {
                        animation: iconRotate 2s infinite;
                    }
                }

                .feedback-form-submit-icon {
                    width: 24px;
                    height: 24px;
                    color: white;
                }
            }
        }
    }
}

// Animation for submit icon
@keyframes iconRotate {
    50% {
        transform: rotate(30deg);
    }
}

// Queries

@media (max-width: 1435px) {
    .feedback-form-container {
        .feedback-form-tag {
            .feedback-form {
                .feedback-form-message {
                    .feedback-form-message-container {
                        .feedback-form-message-title {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .feedback-form-container {
        margin: 0 80px;

        .feedback-form-tag {
            .feedback-form-title {
                margin-top: 120px;
            }
        }
    }
}

@media (max-width: 960px) {
    .feedback-form-container {
        .feedback-form-tag {
            .feedback-form {
                grid-template-columns: 1fr;
                margin-bottom: 60px;
                height: fit-content;

                .feedback-form-input {
                    height: 480px;
                }

                .feedback-form-message {
                    height: 300px;
                }

                .feedback-form-submit-btn {
                    justify-self: self-end;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .feedback-form-container {
        margin: 0 40px;

        .feedback-form-tag {
            .feedback-form {
                .feedback-form-message {
                    .feedback-form-message-container {
                        padding: 20px;
                    }
                }

                .feedback-form-input {
                    height: 360px;

                    .feedback-form-input-container {
                        padding: 20px;
                    }
                }
            }
        }
    }
}
