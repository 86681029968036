.hotel-desc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    .back-btn {
        .back-icon {
            color: #a37826;

            svg {
                width: 35px;
                height: 35px;
                position: absolute;
                top: 20px;
                left: 20px;
                cursor: pointer;
            }
        }
    }

    .hotel-desc-icons {
        position: absolute;
        bottom: 77px;
        right: 0;
        width: 152px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;

        .icon {
            width: fit-content;
            height: fit-content;
            border-radius: 4px;
            border: 1px solid white;

            .hotel-desc-download-icon,
            .hotel-desc-inquire-icon,
            .hotel-desc-share-icon,
            .hotel-desc-active-offers-icon {
                width: 30px;
                height: 30px;
                padding: 6px;
                cursor: pointer;
            }
        }
    }

    .hotel-desc-icon-tooltip-text {
        text-align: right;
        font-size: 1.4rem;
        position: absolute;
        top: 40px;
    }

    .hotel-desc-card-container {
        width: 80%;
        height: 80%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;

        .hotel-desc-card {
            background-size: cover; // was cover in previous code
            background-position: center;
            background-repeat: no-repeat;
            background-color: white;
            position: relative;

            .hotel-desc-card-hotelName {
                font-size: 3rem;

                color: rgb(188, 188, 188);
                text-transform: uppercase;
                position: absolute;
                bottom: 20px;
                left: 20px;
                margin-right: 20px;
            }

            .hotel-desc-card-fact-container {
                color: black;
                position: absolute;
                padding: 40px 46px;
                height: 100%;

                .hotel-desc-card-fact-title {
                    font-size: 2.2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: red;
                }

                .hotel-desc-card-fact-text {
                    margin-top: 10px;
                    font-size: 1.4rem;
                    text-align: justify;
                    font-weight: 500;
                }
            }

            .hotel-desc-card-info-container {
                color: black;
                position: absolute;
                padding: 40px 46px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 100%;
                width: 100%;

                .hotel-desc-card-info-top {
                    display: flex;
                    gap: 20px;
                    justify-content: space-between;

                    .hotel-desc-card-info-top-left,
                    .hotel-desc-card-info-top-right {
                        .hotel-desc-card-info-top-left-title,
                        .hotel-desc-card-info-top-right-title {
                            font-size: 1.3rem;
                            font-weight: bold;
                            text-transform: uppercase;
                        }

                        .hotel-desc-card-info-top-left-text,
                        .hotel-desc-card-info-top-right-text {
                            font-size: 1.2rem;
                            font-weight: 500;
                            margin-top: 10px;
                        }
                    }
                }

                .hotel-desc-card-info-middle,
                .hotel-desc-card-info-bottom {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .hotel-desc-card-info-middle-title,
                    .hotel-desc-card-info-bottom-title {
                        font-size: 1.3rem;
                        text-transform: uppercase;
                        font-weight: bold;
                        color: red;
                    }

                    .hotel-desc-card-info-middle-text,
                    .hotel-desc-card-info-bottom-text {
                        width: 100%;
                        font-size: 1.4rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .hotel-desc-container {
        .hotel-desc-card-container {
            .hotel-desc-card {
                .hotel-desc-card-info-container {
                    .hotel-desc-card-info-middle,
                    .hotel-desc-card-info-bottom {
                        .hotel-desc-card-info-middle-title,
                        .hotel-desc-card-info-bottom-title {
                            width: 50%;
                        }

                        .hotel-desc-card-info-middle-text,
                        .hotel-desc-card-info-bottom-text {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1270px) {
    .hotel-desc-container {
        height: 140vh;

        .hotel-desc-card-container {
            grid-template-columns: repeat(2, 1fr);
        }

        .hotel-desc-icons {
            width: 122px;
        }
    }
}

@media (max-width: 1220px) {
    .hotel-desc-container {
        .hotel-desc-icons {
            width: 110px;
        }
    }
}

@media (max-width: 1100px) {
    .hotel-desc-container {
        .hotel-desc-icons {
            width: 90px;
        }
    }
}

@media (max-width: 900px) {
    .hotel-desc-container {
        .hotel-desc-icons {
            width: 80px;
        }
    }
}

@media (max-width: 800px) {
    .hotel-desc-container {
        .hotel-desc-card-container {
            .hotel-desc-card {
                .hotel-desc-card-info-container {
                    padding: 20px 26px;
                }

                .hotel-desc-card-fact-container {
                    padding: 20px 26px;
                }
            }
        }
        .hotel-desc-icons {
            width: 70px;
        }
    }
}

@media (max-width: 750px) {
    .hotel-desc-container {
        height: fit-content;
        margin-top: 100px;
        flex-direction: column;

        .hotel-desc-card-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;

            .hotel-desc-card-overlay {
                height: 200px;
                width: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.9) 100%
                );
                z-index: 1;
                position: absolute;
                top: 0;

                .hotel-desc-card-hotelName {
                    font-size: 3rem;
                    width: 90%;
                    color: rgb(226, 226, 226);
                    text-transform: uppercase;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    margin-right: 20px;
                }
            }

            .hotel-desc-card {
                height: 200px;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }

                .hotel-desc-card-fact-container {
                    .hotel-desc-card-fact-text {
                        font-size: 1.7rem;
                    }
                }

                .hotel-desc-card-hotelName {
                    color: white;
                    text-shadow: 2px 2px #000000;
                }

                .hotel-desc-card-info-container {
                    height: 200px;

                    .hotel-desc-card-info-middle,
                    .hotel-desc-card-info-bottom {
                        .hotel-desc-card-info-middle-title,
                        .hotel-desc-card-info-bottom-title {
                            font-size: 1.6rem;
                        }

                        .hotel-desc-card-info-middle-text,
                        .hotel-desc-card-info-bottom-text {
                            font-size: 1.8rem;
                        }
                    }
                }
            }

            .hotel-desc-slider {
                .slick-prev,
                .slick-next {
                    display: none !important;
                }
            }
        }

        .hotel-desc-mobile-icons {
            margin: 0 auto;
            margin-top: 60px;
            margin-bottom: 60px;
            display: flex;
            justify-content: space-between;
            width: 80%;

            .mobile-icon {
                border: 1px solid white;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 6px 0;
                cursor: pointer;

                .hotel-desc-download-icon,
                .hotel-desc-share-icon,
                .hotel-desc-inquire-icon,
                .hotel-desc-active-icon {
                    width: 16px;
                    height: 16px;
                    margin: 0 6px;
                }

                p {
                    font-size: 1.8rem;
                    margin-right: 6px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .hotel-desc-container {
        .hotel-desc-mobile-icons {
            .mobile-icon {
                .hotel-desc-download-icon,
                .hotel-desc-share-icon,
                .hotel-desc-inquire-icon,
                .hotel-desc-active-icon {
                    width: 10px;
                    height: 10px;
                    margin: 0 6px;
                }

                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .hotel-desc-container {
        .hotel-desc-card-container {
            .hotel-desc-card {
                .hotel-desc-card-fact-container {
                    padding: 20px;
                }

                .hotel-desc-card-info-container {
                    padding: 20px;
                }
            }
        }
    }
}
