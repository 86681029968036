.video-container {
    height: 700px;
    margin-top: 200px;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    .video-main {
        width: 100%;
        position: relative;
        video {
            width: 100%;
        }

        .video-button {
            font-size: 1.6rem;
            padding: 20px 0px;
            text-align: left;
            cursor: pointer;
            width: fit-content;

            &:hover {
                color: rgb(120, 120, 120);
            }
        }
    }
}

// To make the vertical heading have same space on all three components
@media (max-width: 1250px) {
    .video-container {
        align-items: flex-start;
        height: fit-content;
    }
}

@media (max-width: 600px) {
    .video-container {
        .video-main {
            width: 96%;
            video {
                height: 300px;
                object-fit: fill;
                object-position: center;
            }
        }
    }
}

@media (max-width: 400px) {
    .video-container {
        margin-top: 300px;
    }
}
