.banner-container {
    width: 100%;
    height: 100%;
    top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    .banner-image {
        width: 100%;
    }
}
