@keyframes ldio-owgbjxhwuko {
    0% {
        transform: translate(12px, 80px) scale(0);
    }
    25% {
        transform: translate(12px, 80px) scale(0);
    }
    50% {
        transform: translate(12px, 80px) scale(1);
    }
    75% {
        transform: translate(80px, 80px) scale(1);
    }
    100% {
        transform: translate(148px, 80px) scale(1);
    }
}

@keyframes ldio-owgbjxhwuko-r {
    0% {
        transform: translate(148px, 80px) scale(1);
    }
    100% {
        transform: translate(148px, 80px) scale(0);
    }
}

@keyframes ldio-owgbjxhwuko-c {
    0% {
        background: #aaa;
    }
    25% {
        background: #aaa;
    }
    50% {
        background: #aaa;
    }
    75% {
        background: #aaa;
    }
    100% {
        background: #aaa;
    }
}

.ldio-owgbjxhwuko div {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(80px, 80px) scale(1);
    background: #aaa;
    animation: ldio-owgbjxhwuko 1s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-owgbjxhwuko div:nth-child(1) {
    background: #aaa;
    transform: translate(148px, 80px) scale(1);
    animation: ldio-owgbjxhwuko-r 0.25s infinite cubic-bezier(0, 0.5, 0.5, 1),
        ldio-owgbjxhwuko-c 1s infinite step-start;
}

.ldio-owgbjxhwuko div:nth-child(2) {
    animation-delay: -0.25s;
    background: #aaa;
}

.ldio-owgbjxhwuko div:nth-child(3) {
    animation-delay: -0.5s;
    background: #aaa;
}

.ldio-owgbjxhwuko div:nth-child(4) {
    animation-delay: -0.75s;
    background: #aaa;
}

.ldio-owgbjxhwuko div:nth-child(5) {
    animation-delay: -1s;
    background: #aaa;
}

.loadingio-spinner-ellipsis-js7qwfxgzlb {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background: none;
}

.ldio-owgbjxhwuko {
    width: 200px;
    height: 200px;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-owgbjxhwuko div {
    box-sizing: content-box;
}
