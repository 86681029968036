// ************ Main Component ************ //
.values-container {
    height: 800px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 200px;
}

.values-slider-container {
    width: 100%;

    .slick-slide {
        transform: scale(0.5);
        transition: all 0.3s linear;
    }

    // card is in center
    .slick-center {
        transform: scale(1);
        .card-container {
            border: 1px solid goldenrod;
        }
    }
}

// Card Next and Prev Icons
.carousel-left,
.carousel-right {
    color: goldenrod;
    font-size: 4rem;
    position: absolute;
    bottom: -80px;
    z-index: 1;
}

.carousel-left {
    left: 400px;
}

.carousel-right {
    right: 400px;
}

// ************ Card Component ************ //
.card-container {
    height: 600px;
    border-radius: 12px;
}

.card-header {
    height: 240px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .card-image {
        width: auto;
        max-width: 100%;
        height: 130px;
    }

    .card-title {
        font-size: 2rem;
        font-weight: bold;
    }
}

.card-info {
    padding: 0px 20px 20px 20px;
    font-size: 1.6rem;
    line-height: 2.6rem;
}

// Queries

// To make the vertical heading have same space on all three components
@media (max-width: 1250px) {
    .values-container {
        align-items: flex-start;
        height: 700px;
    }
}

@media (max-width: 1000px) {
    .carousel-left {
        left: 300px;
    }

    .carousel-right {
        right: 300px;
    }
}

@media (max-width: 900px) {
    .values-container {
        height: 540px;
    }
    .card-container {
        height: 450px;
    }
}

@media (max-width: 800px) {
    .carousel-left {
        left: 150px;
    }

    .carousel-right {
        right: 150px;
    }
}

@media (max-width: 400px) {
    .card-container {
        height: 540px;
        padding: 10px;
    }
    .carousel-left {
        left: 100px;
    }

    .carousel-right {
        right: 100px;
    }

    .card-header {
        .card-title {
            font-size: 3rem;
        }
    }
}

@media (max-width: 362px) {
    .card-container {
        height: 520px;
    }
}
